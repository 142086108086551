<template>
    <div>
        <div id="v-step-0">A DOM element on your page.</div>
        <div id="v-step-1">A DOM element on your page.</div>
        <div id="v-step-2">A DOM element on your page.</div>

        <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>
    </div>
</template>

<script>
export default {
    name: 'my-tour',
    data () {
        return {
            myOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish'
                }
            },
            steps: [
                {
                    target: '#v-step-0',
                    header: {
                        title: 'Get Started',
                    },
                    content: `Discover <strong>Vue Tour</strong>!`
                }
            ]
        }
    },
    // mounted: function () {
    //     this.$tours['myTour'].start()
    // }
}
</script>
